import { Button, FormControl, FormHelperText, Input, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from "react-hook-form";
import { sendEvent } from '../gta';
import './Callback.css';
import { config } from '../config';

export function Callback({ onSuccess, setLoading }) {
  const { t } = useTranslation();

  const submit = async ({ cell }) => {
    setLoading(true);

    sendEvent({
      event: 'client',
      category: 'Консультація',
      action: cell
    });

    await fetch(`${ config.api.notifier }?${ new URLSearchParams({ cell }) }`);

    setLoading(false);
    onSuccess();
  };

  const { control, handleSubmit } = useForm({
    defaultValues: {
      cell: ''
    }
  });

  return <>
    <div className='Callback'>
      <FormControl classes={ { root: 'form-control' } }>
        <InputLabel htmlFor="cell-input"> { t('callback.cell') }</InputLabel>
        <Controller
          name="cell"
          rules={ {
            required: true,
            minLength: 10,
            pattern: /^((\+3)?8)?[0-9]{10}$/
          } }
          control={ control }
          render={ ({ field: { onChange, value }, fieldState: { error } }) => <Input id="cell-input"
            aria-describedby={ t('callback') }
            inputProps={ { maxLength: 13 } }
            onChange={ onChange }
            value={ value }
            error={ !!error } /> } />
        <FormHelperText>{ t('callback.helper') }</FormHelperText>
      </FormControl>
    </div>
    <Button variant="contained" color="info"
      onClick={ handleSubmit(submit) }>
      { t('callback.submit') }
    </Button>
  </>
}
