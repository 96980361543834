import React from 'react';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';

Amplify.configure(awsExports);

function AdminApp({ signOut, user }) {
  return (
    <>
      <h1>Hello { user.username }</h1>
      <button onClick={ signOut }>Sign out</button>
    </>
  );
}

export default withAuthenticator(AdminApp);
