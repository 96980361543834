import { useContext, useEffect, useState } from 'react';
import { Box, Button, MobileStepper, Paper, Typography, useTheme } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { config } from './../config';
import './Galleey.css';
import { ConfigContext } from '../contexts';

import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export function Gallery({ productId, setLoading }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [ activeStep, setActiveStep ] = useState(0);
  const [ images, setImages ] = useState([]);
  const { gallery } = useContext(ConfigContext);

  const maxSteps = images.length;

  useEffect(() => {
    setLoading(true);

    fetch(`${ config.api.images }?productId=${ productId }`)
      .then((response) => response.json())
      .then((images) => {
        setLoading(false);
        setImages(images);
      })
  }, [ productId ]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={ { flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' } }>
      <Paper
        square
        elevation={ 0 }
        sx={ {
          display: 'flex',
          alignItems: 'center',
          pl: 2,
        } }
      >
        <Typography>{ images[activeStep]?.title }</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        interval={ gallery.interval }
        style={ { display: 'flex', flexGrow: 1, overflow: 'hidden' } }
        className='swipeable-view'
        axis={ theme.direction === 'rtl' ? 'x-reverse' : 'x' }
        index={ activeStep }
        onChangeIndex={ handleStepChange }
        enableMouseEvents
      >
        { images.map((step, index) => (
          <div key={ step.label } style={ { height: '100%', display: 'flex' } }>
            { Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={ {
                  margin: 'auto',
                  display: 'block',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  overflow: 'hidden'
                } }
                src={ step.imageURL }
                alt={ step.label }
              />
            ) : null }
          </div>
        )) }
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={ maxSteps }
        position="static"
        activeStep={ activeStep }
        nextButton={
          <Button
            size="small"
            onClick={ handleNext }
            disabled={ activeStep === maxSteps - 1 }
          >
            { t('carousel.next') }
            { theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            ) }
          </Button>
        }
        backButton={
          <Button size="small" onClick={ handleBack } disabled={ activeStep === 0 }>
            { theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            ) }
            { t('carousel.back') }
          </Button>
        }
      />
    </Box>
  );
}
