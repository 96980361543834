import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import './i18n/config';
import './index.css';
import App from './App';
import AdminApp from './AdminApp';
import { config } from "./config";
import './gta';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const ConfigContext = createContext({});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<React.StrictMode>
    <ConfigContext.Provider value={ config }>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <App /> }>
            <Route path="/products/:productId" element={ <App /> } />
          </Route>
          <Route path="/admin" element={ <AdminApp /> }></Route>
        </Routes>
      </BrowserRouter>
    </ConfigContext.Provider>
  </React.StrictMode>
);
