import { Wrapper } from '@googlemaps/react-wrapper';
import useDeepCompareEffect from 'use-deep-compare-effect';
import './Contact.css';
import { useRef, useContext, useEffect, useState, isValidElement, cloneElement, Children } from 'react';
import { ConfigContext } from './../contexts';

const Map = ({ children, ...options }) => {
  const mapRef = useRef();

  const [ map, setMap ] = useState();

  useEffect(() => {
    if (mapRef.current && !map) {
      setMap(new window.google.maps.Map(mapRef.current, {}));
    }
  }, [ mapRef, map ]);

  useDeepCompareEffect(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [ map, options ]);

  return <>
    <div ref={ mapRef } className="location-map" />
    ;
    { Children.map(children, (child) => {
      if (isValidElement(child)) {
        // set the map prop on the child component
        return cloneElement(child, { map });
      }
    }) }
  </>;
}

const Marker = (options) => {
  const [ marker, setMarker ] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [ marker ]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [ marker, options ]);

  return null;
};

export function Contact() {
  const { map } = useContext(ConfigContext);

  const defaultProps = {
    center: {
      lat: map.lat,
      lng: map.lng
    },
    zoom: map.zoom
  };

  const render = (status) => {
    return <h3>{ status }</h3>;
  };

  return <div className="Contact">
    <Wrapper
      render={ render }
      apiKey={ map.key }>
      <Map
        center={ defaultProps.center }
        zoom={ defaultProps.zoom }>
        <Marker position={ defaultProps.center } />
      </Map>
    </Wrapper>
  </div>;
}
