export const config = {
  api: {
    config: process.env.REACT_APP_API_CONFIG,
    notifier: process.env.REACT_APP_API_NOTIFIER,
    products: process.env.REACT_APP_API_PRODUCTS,
    images: process.env.REACT_APP_API_IMAGES
  },
  gtmId: process.env.REACT_APP_GTM
};

console.log(process.env);
