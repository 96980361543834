import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import './Footera.css';
import { useContext } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import { ConfigContext } from '../contexts';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export function Footer({ onOpenCallback }) {
  const { t } = useTranslation();
  const { site } = useContext(ConfigContext)
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down('md'));

  return site && (<footer>
    <div className="control-bar">
      { lg &&
        <Button variant="contained" color="info"
          className={ lg ? 'control-bar-btn-xs' : '' }
          onClick={ onOpenCallback }>
          <PhoneCallbackIcon /> &nbsp; { t('callback.title') }
        </Button>
      }

      { !lg &&
        <>
          <Typography variant="body2" color="text.secondary" align="center">
            &nbsp;&nbsp;
            <span>{ new Date().getFullYear() }</span>
            &nbsp;
            { t('copyright') }
            &nbsp;
            <Link color="inherit" href={ site.URL }>
              { site.name }
            </Link>
          </Typography>

          <Avatar sx={ { width: 80, height: 80 } }
            className="rotation-btn">
            <Button variant="contained" color="info"
              sx={ { width: '100%', height: '100%' } }
              className={ lg ? 'control-bar-btn-xs' : '' }
              onClick={ onOpenCallback }>
              <PhoneCallbackIcon fontSize="large"
                sx={ {
                  fontSize: 48
                } } />
            </Button>
          </Avatar>
        </>
      }
    </div>
  </footer>);
}
