import './App.css';
import { Content } from './content';
import { Footer } from './footear';
import { Header } from './header';
import { useEffect, useState } from 'react';
import { config } from './config';
import { ConfigContext } from './contexts';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Callback } from "./callback";
import Dialog from '@mui/material/Dialog';
import { CircularProgress, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';

function App() {
  const [ appConfig, setAppConfig ] = useState({});
  const [ callbackOpen, setCallbackOpen ] = useState(false);
  const [ notificationOpen, setNotificationOpen ] = useState(false);
  const [ requestCount, setRequestCount ] = useState(0);
  const { t } = useTranslation();

  const closNotificationMessage = () => {
    setNotificationOpen(false);
  };

  const setLoading = (value) => {
    setRequestCount((current) => {
      return value ? current + 1 : current - 1;
    });
  }

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={ closNotificationMessage }
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const openCallback = () => {
    setCallbackOpen(true);
  };

  const closeCallback = () => {
    setCallbackOpen(false);
  }

  const handleCallbackSuccess = () => {
    setNotificationOpen(true);
    setCallbackOpen(false);
  }

  useEffect(() => {
    setLoading(true);

    fetch(config.api.config)
      .then(response => response.json())
      .then((data) => {
        setAppConfig(data);

        setLoading(false);
      })
  }, []);

  return (
    <ConfigContext.Provider value={ appConfig }>
      <div className="App">
        <Header onCallback={ openCallback } setLoading={ setLoading } />
        <Content />
        <Footer onOpenCallback={ openCallback } />
        <Snackbar
          open={ notificationOpen }
          autoHideDuration={ 5000 }
          onClose={ closNotificationMessage }
          message={ t('callback.success') }
          action={ action }
        />
        <Dialog open={ callbackOpen } onClose={ closeCallback }>
          <DialogTitle>
            <Typography variant="body2" color="text.secondary" align="center">
              { t('callback.title') }
              <IconButton classes={ { root: 'map-close-btn' } } onClick={ closeCallback }>
                <CloseIcon />
              </IconButton>
            </Typography>
          </DialogTitle>
          <Callback onSuccess={ handleCallbackSuccess } setLoading={ setLoading } />
        </Dialog>
      </div>
      { requestCount && <div className="spinner-box">
        <div className="spinner">
          <CircularProgress />
        </div>
      </div> }
    </ConfigContext.Provider>
  );
}

export default App;
