import { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './Product.css';
import { ConfigContext } from '../contexts';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton { ...other } />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function ProductCard({ product, onCallback, onOpenGallery }) {
  const { carousel } = useContext(ConfigContext);
  const { t } = useTranslation();
  const [ expanded, setExpanded ] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (<Card
    classes={ { root: 'sub-product-card' } }
    sx={ { maxWidth: carousel.tileWidth, width: carousel.tileWidth } }>
    <CardHeader
      avatar={
        <Avatar aria-label={ product.avatar }>
          <img src={ product.avatar } alt={ product.avatar } />
        </Avatar>
      }
      disableTypography={ true }
      title={ <Typography variant="body2" color="text.secondary"
        dangerouslySetInnerHTML={ { __html: product.category } } /> }
      subheader={ <Typography variant="body2" color="text.secondary"
        dangerouslySetInnerHTML={ { __html: product.name } } /> }
    />
    <CardMedia
      component="img"
      height={ carousel.titleImageHeight }
      image={ product.image }
      alt={ product.name }
    />
    <CardActions>
      <Button size="small" onClick={ onOpenGallery }>{ t('carousel.view') }</Button>
    </CardActions>

    <CardContent>
      <Typography variant="body2" color="text.secondary" dangerouslySetInnerHTML={ { __html: product.summary } } />
    </CardContent>
    <CardActions disableSpacing>
      <IconButton aria-label="callback"
        onClick={ onCallback }>
        <AddIcCallIcon />
      </IconButton>
      <IconButton aria-label="photo gallery"
        onClick={ onOpenGallery }>
        <PhotoLibraryIcon />
      </IconButton>
      <ExpandMore
        expand={ expanded }
        onClick={ handleExpandClick }
        aria-expanded={ expanded }
        aria-label={ t('product.showMore') }
      >
        <ExpandMoreIcon />
      </ExpandMore>
    </CardActions>
    <Collapse in={ expanded } timeout="auto" unmountOnExit>
      <CardContent>
        <Typography variant="body2" color="text.secondary" paragraph
          dangerouslySetInnerHTML={ { __html: product.details } } />
      </CardContent>
    </Collapse>
  </Card>);
}
