import Carousel from 'react-material-ui-carousel'
import { useContext, useEffect, useState } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { config } from '../config';
import { CarouselItem } from './CarouselItem';
import './Header.css';
import { sendEvent } from '../gta';
import { ConfigContext } from '../contexts';
import { useTranslation } from 'react-i18next';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useParams } from 'react-router-dom';
import { Contact } from '../contact';

export function Header({ onCallback, setLoading }) {
  const [ currentPage, setCurrentPage ] = useState(0);
  const { t } = useTranslation();
  const { carousel } = useContext(ConfigContext);
  const [ products, setProducts ] = useState([]);
  const [ locationOpen, setLocationOpen ] = useState(false);
  const { contact } = useContext(ConfigContext)
  const { productId } = useParams();

  useEffect(() => {
    setLoading(true);

    fetch(config.api.products)
      .then((response) => response.json())
      .then((products) => {
        setProducts(products);
        setLoading(false);
      });

  }, []);

  useEffect(() => {
    if (products.length && productId) {
      setCurrentPage(parseInt(productId, 10) - 1);
    }
  }, [ products, productId ]);

  const onCarouselChange = (current) => {
    if (currentPage !== current) {
      setCurrentPage(current);
    }
  };

  const getPreviousPage = () => {
    return products[currentPage === 0 ? products.length - 1 : currentPage - 1];
  }

  const geNextPage = () => {
    return products[currentPage === products.length - 1 ? 0 : currentPage + 1];
  }

  const changeProduct = (name) => {
    sendEvent({
      event: 'client', category: 'продукт', action: `${ name }`
    });
  };

  const openLocation = () => {
    setLocationOpen(true);

    sendEvent({
      event: 'client', category: `контакти`, action: 'мапа'
    });
  };

  const closeLocation = () => {
    setLocationOpen(false);
  }

  const dialNumber = () => {
    sendEvent({
      event: 'client',
      category: 'Консультація',
      action: 'Виклик на мапі'
    });
  };

  return carousel && (<>
    <ButtonGroup className="header-control-bar" variant="contained">
      { products.map(({ name }, i) => <Button variant="contained" key={ name }
        className="header-control-bar-btn"
        color={ currentPage === i ? 'warning' : 'info' }
        onClick={ () => {
          setCurrentPage(i);
          changeProduct(name)
        } }><KeyboardDoubleArrowRightIcon /> { name }</Button>) }
      <Button variant="contained"
        onClick={ openLocation }
        className="header-control-bar-btn"
        color="info">
        <KeyboardDoubleArrowRightIcon />
        { t('location') }
      </Button>
    </ButtonGroup>
    <header className="Header">
      <Carousel autoPlay={ carousel.interval > 100 }
        fullHeightHover={ false }
        indicators={ false }
        interval={ carousel.interval }
        index={ currentPage }
        onChange={ onCarouselChange }
        NavButton={ ({ onClick, className, style, next, prev }) => {
          return (false && <Button onClick={ onClick } className={ className }>
            { next && geNextPage()?.name }
            { prev && getPreviousPage()?.name }
          </Button>)
        } }>
        { products.map((item, i) => <CarouselItem
          key={ item.name }
          item={ item }
          setLoading={ setLoading }
          onCallback={ onCallback } />)
        }
      </Carousel>
    </header>
    <Dialog open={ locationOpen } fullScreen onClose={ closeLocation }>
      <DialogTitle>
        <Typography variant="body2" color="text.primary" align="center">
          { t('address') }
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a href={ `tel:${ contact.cell }` } onClick={ dialNumber }>{ contact.cell }</a>
          <IconButton classes={ { root: 'map-close-btn' } } onClick={ closeLocation }>
            <CloseIcon />
          </IconButton>
        </Typography>
      </DialogTitle>
      <Contact />
    </Dialog>
  </>);
}

