import TagManager from 'react-gtm-module'
import { config } from '../config';

const tagManagerArgs = {
  gtmId: config.gtmId
}

TagManager.initialize(tagManagerArgs);

export function sendEvent(event) {
  window.dataLayer.push(event);
}
