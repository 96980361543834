import { useContext, useState } from 'react';
import {
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  IconButton,
  ImageList,
  ImageListItem,
  Paper,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Gallery } from '../gallery';
import './CarouselItem.css';
import { ConfigContext } from '../contexts';
import { sendEvent } from '../gta';
import { ProductCard } from "./Product";

export function CarouselItem({ item, onCallback, setLoading }) {
  const { t } = useTranslation();
  const [ galleryOpen, setGalleryOpen ] = useState(false);
  const { header } = useContext(ConfigContext)

  const closeGallery = () => {
    setGalleryOpen(false);
  };

  const openGallery = () => {
    setGalleryOpen(true);

    sendEvent({
      event: 'client',
      category: `продукт ${ item.name }`,
      action: 'галерея'
    });
  };

  return (
    <>
      <Dialog fullScreen open={ galleryOpen } onClose={ closeGallery }>
        <DialogTitle>
          <Typography variant="body1" color="text.primary" align="center">
            { t('portfolio') }
            <IconButton classes={ { root: 'map-close-btn' } } onClick={ closeGallery }>
              <CloseIcon />
            </IconButton>
          </Typography>
        </DialogTitle>
        <Gallery productId={ item._id } setLoading={ setLoading } />
      </Dialog>
      <Paper>
        <Card>
          <ImageList
            gap={ 0 }
            classes={ { root: 'carousel-root' } }
            sx={ { height: header.height } }
            variant={ header.variant }
            cols={ header.cols }
            rowHeight={ header.rowHeight }
          >
            { item.images.map((image) => (
              <ImageListItem key={ image.url } cols={ image.cols || 1 } rows={ image.rows || 1 }
                onClick={ openGallery }>
                <img
                  alt={ item.name }
                  src={image.url }
                  loading="lazy"
                />
              </ImageListItem>
            )) }
          </ImageList>
          <CardContent>
            <div className="products-tile-list">
              { item.subProducts?.map((product, index) => <ProductCard
                key={ index }
                product={ product }
                onOpenGallery={ openGallery }
                onCallback={ onCallback } />) }
            </div>
          </CardContent>
        </Card>
      </Paper>
    </>
  )
}
